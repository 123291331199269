<template>
  <div class="project-refund-status" :class="getStatusClass()">
    {{ getStatusLabel() }}
  </div>
</template>

<script>
import { projectRefundStatus as STATUS } from "@/enums";

export default {
  components: {},
  mixins: [],
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusLabel() {
      switch (this.status) {
        case STATUS.PENDING_REQUEST:
          return "Pending Request";
        case STATUS.PENDING_CONFIRM:
          return "Pending Confirm";
        case STATUS.ACCEPTED:
          return "Accepted";
        case STATUS.REJECTED:
          return "Rejected";

        default:
          return this.status;
      }
    },
    getStatusClass() {
      switch (this.status) {
        case STATUS.PENDING_REQUEST:
          return "pending-request";
        case STATUS.PENDING_CONFIRM:
          return "pending-confirm";
        case STATUS.ACCEPTED:
          return "accepted";
        case STATUS.REJECTED:
          return "rejected";

        default:
          return "other";
      }
    }
  }
};
</script>

<style lang="scss">
.project-refund-status {
  border-radius: 5px;
  padding: 8px 16px;
  text-align: center;

  @mixin status($class, $bg-color, $color) {
    &.#{$class} {
      background: $bg-color;
      color: $color;
    }
  }

  @include status("pending-request", $color-warn, white);
  @include status("pending-confirm", lighten($color-main, 20), white);
  @include status("accepted", $color-success, white);
  @include status("rejected", $color-danger, white);
  @include status("other", #ddd, #6f6f6f);
}
</style>
